import React, { useEffect } from "react"
import styled from "styled-components"
import { Layout } from "../components"
import LinuxAdminKaJobDetail from "./linux-admin-ka-job-detail"

import VideoPoster from "../images/video-poster.jpg"
import Tucnak from "../images/tucnak/tucnak.svg"
import OgImage from "../images/position/og-image-linux-admin.jpg"
import { Helmet } from "react-helmet"

const Banner = styled.div`
  position: relative;
  padding: 30px 0;
  max-height: calc(100vh - 59px);
  background: ${(props) => props.theme.color.primary};
  overflow: hidden;

  &:before {
    @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
      content: "";
      background: url(${Tucnak}) no-repeat 0 0;
      background-size: contain;
      width: 250px;
      height: 407px;
      display: block;
      position: absolute;
      bottom: -150px;
      right: 50px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 0;
  }
`

const BannerVideoWrapper = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    display: block;
  }
`

export default function LinuxAdmin() {
  useEffect(() => {
    document.body.classList.add("modal-open")

    return function cleanup() {
      document.body.classList.remove("modal-open")
    }
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>Přidej se k nám do igloo! | igloonet</title>
        <meta
          name="description"
          content="Hledáme šikovnou full-time parťačku či
          parťáka do malého týmu v Brně. Někoho, kdo je zodpovědný,
          spolehlivý, s lecčím si poradí a pomůže nám jak s běžnými
          adminovskými starostmi, tak s dalším rozvojem."
        />
        <meta property="og:title" content="Přidej se k nám do igloo!" />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <LinuxAdminKaJobDetail />

      <Banner>
        <BannerVideoWrapper>
          <img
            className="lazy"
            src={VideoPoster}
            alt="text"
            width="1920"
            height="1080"
          />
        </BannerVideoWrapper>
      </Banner>
    </Layout>
  )
}
